import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { batch, useDispatch, useSelector } from 'react-redux';

import { ColorUtils } from '../../../../../utils/ColorUtils';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { NavLink } from '../../../../atoms/Link/Link';
import { MobilePanelFooter } from '../../../../atoms/MobilePanelFooter/MobilePanelFooter';
import { AvatarImagesData, getAvatarAnalyticsKey } from '../../../../constants/AvatarImagesData';
import { HeaderSideMenuTabs } from '../../../../constants/HeaderSideMenuTabs';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { SubscriptionSource } from '../../../../models/Subscription/SubscriptionData';
import { RegistrationSource } from '../../../../models/User/RegistrationSource';
import { UserModel } from '../../../../models/User/UserModel';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../../../services/Analytics/LeanplumAnalytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { GameService } from '../../../../services/GameService';
import { RECAPTCHA_ACTIONS, useRecaptchaSimple } from '../../../../services/hooks/useRecaptchaSimple';
import { LocalStorageService } from '../../../../services/LocalStorage';
import { ProfileProcessService } from '../../../../services/ProfileProcessService';
import UserService, { AuthType } from '../../../../services/UserService';
import {
    setProfilePanelState,
    setSideMenuActivePage,
    setSignInUpState,
    setSnackbarData,
} from '../../../../store/ducks/layout';
import { setSubscriptionSource } from '../../../../store/ducks/subscription/common';
import { UserEffects } from '../../../../store/effects/user.effects';
import { AvatarsList } from '../../../AvatarsList/AvatarsList';
import styles from './AvatarsPanel.css';
import { FramesPanel } from './FramesPanel/FramesPanel';
import { MiscUtils } from '../../../../../utils/MiscUtils';

type AvatarsPanelProps = {
    user: UserModel;
    activeState: ProfileRightSideRoutes;
    saveUser: (user: any) => void;
    closePanel: () => void;
};

export const AvatarsPanel = React.memo(({ user, saveUser, activeState, closePanel }: AvatarsPanelProps) => {
    const [selectedFilename, setSelectedFilename] = useState(user?.avatar || '');

    const [isCustomizePanel, setIsCustomizePanel] = useState(false);

    const [isSignUp, setIsSignUp] = useState(false);

    const dispatch = useDispatch();
    const userCustomizedBackground = useSelector((state) => state.framesPanel?.background || '');
    const userCustomizedFrame = useSelector((state) => state.framesPanel?.frame || '');
    const opensModalElementRef = useSelector((state) => state.opensModalElementRef);

    const activeSignInUpState = useSelector((state) => state.signInUpState.activeComponent);

    const authDataContext = useContext(AuthDataContext);
    const { email, password, checkBox, authProvider, authProviderToken } = authDataContext.value;

    const BTN_LABEL_NEXT = 'Next';
    const BTN_LABEL_SAVE = 'Save';
    const BTN_LABEL_SAVE_AND_FINISH = 'Save and Finish';

    const categoryPageName = useSelector((state) => state.categoryPageName);
    const gameArkadiumSlug = useSelector((state) => state.gameArkadiumSlug);
    const games = useSelector((state) => state.games);
    const socialRegistrationLocation = useSelector((state) => state.socialRegistrationLocation);

    const [loading, setLoading] = useState(false);

    const [captchaToken, updateCaptchaToken] = useRecaptchaSimple(
        isSignUp ? RECAPTCHA_ACTIONS.SIGN_UP : RECAPTCHA_ACTIONS.SIGN_IN
    );

    const authProviderFromLocalStorage = LocalStorageService.getItem('authProvider');

    const game = gameArkadiumSlug
        ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
        : '';

    useEffect(() => {
        const isCustomizePanelOpened = activeState === ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL;
        setIsCustomizePanel(isCustomizePanelOpened);
        setIsSignUp(activeSignInUpState === SignInUpComponents.PERSONALIZE_PROFILE);
    }, [activeState]);

    useEffect(() => {
        return () => {
            opensModalElementRef?.focus();
        };
    }, []);

    const handleOpenSubscription = () => {
        UserService.closePanel();
        dispatch(setSubscriptionSource(SubscriptionSource.AVATAR_PANEL));
        UserService.openSubscriptionPanel();
    };

    const handleSocialRequest = (userData) => {
        if (authProvider === AuthType.Facebook) {
            return UserService.loginViaFacebook(
                authProviderToken,
                email,
                checkBox,
                new URL(UserService.generateUrl()),
                null,
                userData,
                captchaToken
            ).then((resData) => {
                setLoading(false);
                const res = resData.errorCode;
                Analytics.trackEvent(Analytics.profile.socialEmailRequest());
                if (res === 200) {
                    LocalStorageService.setItem('authProvider', AuthType.Facebook);
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Facebook });
                    UserService.openAfterSignPanel();
                } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Facebook });
                    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            });
        } else if (authProvider === AuthType.Google) {
            return UserService.loginViaGoogle(
                authProviderToken,
                email,
                checkBox,
                new URL(UserService.generateUrl()),
                null,
                userData,
                captchaToken
            ).then((resData) => {
                setLoading(false);
                const res = resData.errorCode;
                Analytics.trackEvent(Analytics.profile.socialEmailRequest());
                if (res === 200) {
                    LocalStorageService.setItem('authProvider', AuthType.Google);
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Google });
                    UserService.openAfterSignPanel();
                } else if (UserService.errorCodeToEnum(res) === 'UserNotConfirmedError') {
                    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Google });
                    dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                } else {
                    dispatch(
                        setSnackbarData({
                            isOpened: true,
                            message: UserService.errorCodeToText(res),
                            type: 'error',
                            parentNode: 'rightSideMenu',
                        })
                    );
                }
                updateCaptchaToken(res !== 200);
            });
        } else {
            dispatch(
                setSnackbarData({
                    isOpened: true,
                    message: 'No social network selected',
                    type: 'error',
                    parentNode: 'rightSideMenu',
                })
            );
        }
    };

    const handleClickNextBtn = () => {
        const isPremiumFilename = selectedFilename?.includes('premium');
        setLoading(true);

        // for user registration
        if (isSignUp) {
            const userData = {
                name: authDataContext.value.name,
                avatar: selectedFilename,
                avatarBackground: userCustomizedBackground,
            };
            if (user) {
                // flow: the user registered for the first time through a social network
                // figma: https://www.figma.com/file/3ULngpxCaEdP2yeCUHYEm8/Smarter-Login-%3E-DEV?node-id=583%3A13922
                // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517540075385&cot=14
                return UserService.userUpdate(userData)
                    .then(() => {
                        UserService.closePanel();
                    })
                    .catch((err) => {
                        dispatch(
                            setSnackbarData({
                                isOpened: true,
                                message: UserService.errorCodeToText(err),
                                type: 'error',
                                parentNode: 'rightSideMenu',
                            })
                        );
                    });
            } else if (authProvider && authProvider !== AuthType.EmailPassword) {
                // flow: add an email to the user if he registered through a social network through a phone number
                // figma: https://www.figma.com/file/3ULngpxCaEdP2yeCUHYEm8/Smarter-Login-%3E-DEV?node-id=583%3A13950
                // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517611608158&cot=14
                handleSocialRequest(userData);
            } else {
                // flow: register user via email
                // figma: https://www.figma.com/file/3ULngpxCaEdP2yeCUHYEm8/Smarter-Login-%3E-DEV?node-id=580%3A13847
                // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517115541698&cot=14

                return UserService.userRegister(email, password, checkBox, false, userData, captchaToken).then(
                    (res) => {
                        // setLoading(false);
                        if (res && res.code === 200) {
                            LocalStorageService.setItem('authProvider', AuthType.EmailPassword);
                            const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedFilename);
                            LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.REGISTRATION);
                            // To have LP userAttribute {registered: true} set here we don't have email confirmed,
                            // final Eagle user id, and LP session started with this id to set it in LP to correct user
                            const { uid } = res;
                            Analytics.trackEvent(
                                Analytics.profile.registration(
                                    categoryPageName,
                                    game,
                                    RegistrationSource.EMAIL,
                                    avatarAnalyticsKey,
                                    userCustomizedBackground,
                                    socialRegistrationLocation,
                                    uid
                                )
                            );
                            batch(() => {
                                dispatch(
                                    setProfilePanelState({
                                        isOpened: false,
                                        caption: '',
                                        target: ProfileRightSideRoutes.NOOP,
                                    })
                                );
                                dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                                dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
                            });
                        } else {
                            dispatch(
                                setSnackbarData({
                                    isOpened: true,
                                    message: UserService.errorCodeToText(res),
                                    type: 'error',
                                    parentNode: 'rightSideMenu',
                                })
                            );
                        }
                        setLoading(false);
                        updateCaptchaToken(res !== 200);
                    }
                );
            }
        }

        if (!isCustomizePanel) {
            // FOR Select panel
            saveUser({
                ...{
                    avatar: selectedFilename,
                    subscriberAvatar: {
                        avatar: isPremiumFilename ? selectedFilename : null,
                        frame: user?.subscriberAvatar?.frame,
                    },
                },
                ...ProfileProcessService.getStepForUpdate(12), // this callback also sends a request to BackEnd -> save user
            });
            dispatch(
                setProfilePanelState({
                    isOpened: true,
                    caption: '',
                    target: ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL,
                })
            );
            setLoading(false);
            const avatarAnalyticsKey = AvatarImagesData.find(
                (item) => item.filename === selectedFilename
            )?.analyticsKey;
            Analytics.trackEvent(
                Analytics.profile.profileAvatar(
                    avatarAnalyticsKey || '',
                    MiscUtils.getAuthSource(authProviderFromLocalStorage)
                )
            );
            // openweb hack to update user on openweb load
            LocalStorageService.removeItem('SPOTIM_ACCESS_TOKEN', false);
        } else {
            // FOR Customize panel
            // SAVe user to BD request
            dispatch(
                UserEffects.updateUser({
                    avatarBackground: userCustomizedBackground || '',
                    subscriberAvatar: {
                        frame: userCustomizedFrame,
                    },
                })
            );
            setLoading(false);
            const avatarBackgroundColor = ColorUtils.getBackgroundColorName(userCustomizedBackground);
            Analytics.trackEvent(
                Analytics.profile.profileAvatarCustomize(
                    avatarBackgroundColor,
                    userCustomizedFrame,
                    MiscUtils.getAuthSource(authProviderFromLocalStorage)
                )
            );
            dispatch(
                setProfilePanelState({
                    isOpened: false,
                    caption: '',
                    target: ProfileRightSideRoutes.NOOP,
                })
            );
        }
    };

    const getNextBtnLabel = () => {
        if (isSignUp) {
            return BTN_LABEL_SAVE_AND_FINISH;
        }

        if (isCustomizePanel) {
            return BTN_LABEL_SAVE;
        }

        return BTN_LABEL_NEXT;
    };

    const getBody = () => {
        if (isCustomizePanel) {
            return <FramesPanel isSignUp={isSignUp} />;
        }
        return (
            <AvatarsList
                isSignUp={isSignUp}
                selectedAvatar={selectedFilename}
                onChange={(v) => {
                    setSelectedFilename(v);
                }}
            />
        );
    };

    const getFirsSentence = () => {
        if (isCustomizePanel) {
            return 'Customize your avatar.';
        }
        return 'Select your avatar.';
    };

    const handleClose = () => {
        if (activeSignInUpState === SignInUpComponents.PERSONALIZE_PROFILE) {
            batch(() => {
                dispatch(
                    setProfilePanelState({
                        isOpened: false,
                        caption: '',
                        target: ProfileRightSideRoutes.NOOP,
                    })
                );
                dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
                dispatch(setSignInUpState(SignInUpComponents.PERSONALIZE_PROFILE));
            });
        } else {
            closePanel();
        }
    };

    return (
        <div
            className={styles.content}
            role="tabpanel"
            aria-labelledby={
                isCustomizePanel ? ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL : ProfileRightSideRoutes.AVATARS_PANEL
            }
        >
            {!isSignUp && (
                <>
                    {!UserService.isUserSubscriber() && (
                        <div className={styles.info}>
                            <strong>{getFirsSentence()}</strong> Access the full selection of premium avatars and frames
                            when you subscribe to <NavLink onClick={handleOpenSubscription}>Arkadium Advantage</NavLink>
                        </div>
                    )}
                </>
            )}

            {getBody()}
            <Responsive>
                <MobilePanelFooter twoColumns>
                    <Button outlined onClick={handleClose} className={classNames(styles.button, styles.btnClose)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleClickNextBtn}
                        loading={loading}
                        className={classNames(styles.button, isSignUp && styles.btnSignUp)}
                    >
                        {getNextBtnLabel()}
                    </Button>
                </MobilePanelFooter>
            </Responsive>
        </div>
    );
});
