import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { AvatarBackgroundColors } from '../../../../../../constants/AvatarBackgroundColors';
import { setFramesPanelState } from '../../../../../../store/ducks/layout';
import styles from './BackgroundPicker.css';

export const BackgroundPicker: FC = React.memo(() => {
    const userFrame = useSelector((store) => store.user?.subscriberAvatar?.frame);
    const avatarBackground = useSelector((state) => state.user?.avatarBackground || '');
    const frame = useSelector((state) => state.framesPanel.frame);

    const [selectedColor, setSelectedColor] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedColor(avatarBackground);
        saveStoreData(avatarBackground, frame);
    }, []);

    const saveStoreData = (color: string, frame: string) => {
        dispatch(
            setFramesPanelState({
                background: color,
                frame: frame || userFrame,
            })
        );
    };

    return (
        <fieldset className={styles.container}>
            <legend className={styles.title}>Choose your background color</legend>
            <div className={styles.colorPicker}>
                {AvatarBackgroundColors.map(({ color, name }) => (
                    <Item
                        colorRGBA={color}
                        colorName={name}
                        frame={frame}
                        selectedColor={selectedColor}
                        setSelectedColor={(v) => {
                            setSelectedColor(v);
                            saveStoreData(v, frame);
                        }}
                    />
                ))}
            </div>
        </fieldset>
    );
});

interface ItemProps {
    colorRGBA: string;
    colorName: string;
    frame?: string;
    selectedColor: string;
    setSelectedColor: (value: string) => void;
}

const Item = ({ colorRGBA, colorName, selectedColor, setSelectedColor }: ItemProps) => {
    const isSelectedColor = selectedColor === colorRGBA;

    return (
        <div key={colorName} className={styles.colorItemWrapper}>
            <input
                type="radio"
                name="color"
                id={colorName}
                value={colorRGBA}
                className={styles.screenReaderOnly}
                checked={isSelectedColor}
                onChange={() => {
                    setSelectedColor(colorRGBA);
                }}
            />
            <label
                htmlFor={colorName}
                style={{ backgroundColor: colorRGBA }}
                className={classNames(styles.colorItem, { [styles.selected]: isSelectedColor })}
            >
                <span className={styles.screenReaderOnly}>{colorName}</span>
            </label>
            <div
                style={{ borderColor: isSelectedColor ? colorRGBA : 'transparent' }}
                className={classNames({ [styles.hoverItem]: isSelectedColor })}
            />
        </div>
    );
};
